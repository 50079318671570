@use '@angular/material' as mat;
@import '~@angular/material/theming';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i|Material+Icons');

@include mat.core();
//https://www.color-hex.com/color/3f1c16

$MAXIMAL_WIDTH_FOR_MOBILE_VIEW: 1000px;

$bv-brand: (
  50 : #e1e8ed,
  100 : #b3c6d1,
  200 : #81a0b3,
  300 : #4f7a95,
  400 : #295e7e,
  500 : #034167,
  600 : #033b5f,
  700 : #023254,
  800 : #022a4a,
  900 : #011c39,
  A100 : #6fa7ff,
  A200 : #3c88ff,
  A400 : #0969ff,
  A700 : #005def,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$rjdm-silver: (
  50 : #fefefe,
  100 : #fcfcfc,
  200 : #fbfbfb,
  300 : #f9f9f9,
  400 : #f7f7f7,
  500 : #f6f6f6,
  600 : #f5f5f5,
  700 : #f3f3f3,
  800 : #f2f2f2,
  900 : #efefef,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$rjdm-light: (
  50 : #fef2e1,
  100 : #fbdeb4,
  200 : #f9c982,
  300 : #f7b350,
  400 : #f5a22b,
  500 : #f39205,
  600 : #f18a04,
  700 : #ef7f04,
  800 : #ed7503,
  900 : #ea6301,
  A100 : #ffffff,
  A200 : #ffeade,
  A400 : #ffc9ab,
  A700 : #ffb991,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
;

$primary: mat.define-palette($bv-brand);
$secondary: mat.define-palette($rjdm-light);
$accent: mat.define-palette($rjdm-silver);
$warn: mat.define-palette(mat.$red-palette);
$rjdm-light-gray: rgb(246, 246, 246);
$primary-theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
  ),
  typography: mat.define-typography-config(
    $font-family: Dosis,
  )
));
$secondary-theme: mat.define-light-theme((
  color: (
    primary: $secondary,
    accent: $accent,
  ),
  typography: mat.define-typography-config(
    $font-family: Dosis,
  )
));
@include mat.all-component-themes($primary-theme);
@include mat.progress-bar-theme($secondary-theme);

router-outlet.router-flex + * {
  display: flex;
  flex: 1 1 auto !important;
  flex-direction: column;
  height: 100%;
}

.mat-stroked-button {
  background-color: white !important;
}

.m-button-with-icon {
  width: 60px;
  height: 55px;
}

.m-button-with-icon mat-icon {
  transform: scale(1.3);
}

.accent-color {
  color: mat.get-color-from-palette($accent) !important;
}

.primary-color {
  color: mat.get-color-from-palette($primary) !important;
}

.rjdm-snackbar {
  background-color: mat.get-color-from-palette($primary);
  color: white;

  .mat-simple-snackbar-action {
    color: white;
  }
}

* {
  font-size: large;
  font-family: 'Dosis', sans-serif;
}

.mat-icon {
  font-family: 'Material Icons', serif !important;
}

mat-icon.mat-card-avatar {
  width: 30px;
  height: 30px;
  font-size: 30px;
}

::ng-deep .ql-container.ql-disabled {
  border: none !important;
  height: 100%;
}

:host ::ng-deep .event-content img {
  max-width: 100%;
}

::ng-deep .mat-checkbox-layout {
  white-space: normal !important;
}

::ng-deep .mat-select-value-text {
  white-space: pre-wrap;
}

::ng-deep .mat-select-panel mat-option.mat-option {
  height: auto;
}

.mat-option {
  height: auto !important;
  border-bottom: mat.get-color-from-palette($primary) 1px solid;
  border-left:  mat.get-color-from-palette($primary) 2px solid;
  border-right:  mat.get-color-from-palette($primary) 2px solid;
}
.mat-option:last-of-type {
  border-bottom: mat.get-color-from-palette($primary) 2px solid;
}
.mat-option:first-of-type {
  border-top: mat.get-color-from-palette($primary) 2px solid;
}
::ng-deep .mat-option-text.mat-option-text {
  white-space: normal;
}

.double-field-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.triple-field-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}


.triple-field-container mat-form-field {
  width: 32%;
}

.double-field-container mat-form-field {
  width: 49%;
}

.multiple-form-field .mat-form-field-infix {
  display: flex !important;
  flex-direction: row;
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: transparent;
}

@media screen and (max-width: 500px) {
  ::ng-deep mat-horizontal-stepper .mat-horizontal-stepper-header-container {
    display: flex;
    flex-direction: column;
  }

  ::ng-deep .mat-stepper-horizontal-line {
    display: none;
  }
  ::ng-deep mat-step-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  ::ng-deep .mat-step-icon {
    margin: 0 10px;
  }

}

.make-yellow {
  background-color: lightyellow !important;
}

.make-red {
  background-color: indianred !important;
}

.make-white {
  background-color: white !important;
}

.make-green {
  background-color: lightgreen !important;
}

.make-salmon {
  background-color: lightsalmon !important;
}

.make-yellow {
  background-color: yellow;
}

.make-gray {
  background-color: lightgray;
}


html,
body {
  height: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
}

.large-font {
  font-size: large !important;
}

.medium-font {
  font-size: medium !important;
}

.bold-font {
  font-weight: bold !important;
}

.italic-font {
  font-style: italic !important;
}

.width-100 {
  width: 100%;
}

.color-white {
  color: white
}

.height-100 {
  height: 100%;
}

.flexed-center-hv {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.flexed-center-h {
  display: flex !important;
  align-items: center;
}

.flexed-center-v {
  display: flex !important;
  justify-content: center;
}


.app-rounded-container {
  border-style: solid;
  border-color: lightgray;
  border-radius: 5px;
  border-width: 2px;
  position: relative;
  display: flex !important;
  align-items: center;
}

.app-rounded-container mat-label {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10px, -50%);
  background-color: white;
}

.app-rounded-container:hover {
  border-color: black;
}

.app-rounded-container span {
  margin: 10px;
}

button {
  white-space: pre-wrap !important;
}

.delete-button {
  color: red;
}

.mat-card-header-text {
  width: 100% !important;
}

app-readonly-text-view {
  display: flex;
}

@media (min-width: $MAXIMAL_WIDTH_FOR_MOBILE_VIEW) {
  .mat-table[matTableResponsive] {
    .mat-header-row {
      th.mat-header-cell {
        overflow-wrap: anywhere;
      }
    }

    .mat-footer-row {
      font-weight: bold;
      text-align: center;
      font-size: large;
    }
  }

  .mat-header-cell {
    border-right: 1px solid mat.get-color-from-palette($accent);
    border-top: 1px solid mat.get-color-from-palette($accent);
    padding: 5px;
  }

  .mat-header-cell:first-child {
    border-left: 1px solid mat.get-color-from-palette($accent);
  }

  .mat-cell {
    border-right: 1px solid mat.get-color-from-palette($accent);
    padding: 5px;
  }

  .mat-cell:first-child {
    border-left: 1px solid mat.get-color-from-palette($accent);
  }

  .mat-header-cell {
    word-break: break-word;
    padding: 5px;
  }

  .mat-cell span {
    word-break: break-word;
    padding: 5px;
  }
  .mat-row:hover {
    background-color: mat.get-color-from-palette($accent);
    cursor: pointer;
  }
}

@media (max-width: $MAXIMAL_WIDTH_FOR_MOBILE_VIEW) {
  .mat-table[matTableResponsive] {
    .mat-header-row {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      position: sticky;
      top: 0;
      z-index: 2;

      th.mat-header-cell {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 1rem 0.5rem;
        justify-content: space-around;
        word-break: break-all;
        white-space: pre-wrap;

        & > .mat-sort-header-container {
          padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
        }

        &:not([mat-sort-header]),
        &.mat-sort-header-disabled,
        &.mat-column-actions {
          display: none;
        }
      }
    }

    .mat-row {
      height: auto;

      td.mat-cell:not(.mat-column-actions) {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0.5rem;
        text-align: right;
        word-break: break-word;

        & > * {
          grid-column: 2;
        }

        &:before {
          content: attr(data-column-name);
          text-align: left;
        }
      }

      td.mat-column-actions {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        justify-content: center;
        padding: 10px;

        & > * {
          border: 1px solid mat.get-color-from-palette($primary);
          border-radius: 0;
          width: 100% !important;
        }
      }

      &:last-of-type td:last-of-type {
        border: none;
      }

      &:not(:last-of-type) td:last-of-type {
        border-bottom: 0.5rem solid #fafafa;
      }
    }
  }
}

.mat-button-toggle-button {
  height: 100%;
}

.mat-raised-button {
  flex-grow: 1;
}

.mat-tooltip{
  background-color: rgba(0,0,0,1) !important;
  color: #ffffff;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px;
}

.mat-stroked-button {
  flex-grow: 1;
}

.button-loading {
  .mat-button-wrapper {
    visibility: hidden;
  }

  .mat-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
}

@media (max-width: $MAXIMAL_WIDTH_FOR_MOBILE_VIEW) {
  button:not(.mat-icon-button) {
    width: 100%;
  }
}

.mat-paginator-page-size-select.mat-form-field-appearance-outline {
  width: 92px !important;
}

.mat-row:nth-child(even){
  background-color: white;
}

.mat-row:nth-child(odd){
  background-color: mat.get-color-from-palette($accent);
}

tr:nth-child(even){
  background-color: white;
}

tr:nth-child(odd){
  background-color: mat.get-color-from-palette($accent);
}

.mat-form-field-type-richeditor.mat-form-field-can-float .mat-form-field-label-wrapper, .mat-form-field-type-file.mat-form-field-can-float .mat-form-field-label-wrapper {
  top: -22px;
}
.mat-form-field-type-richeditor.mat-form-field-should-float .mat-form-field-label-wrapper, .mat-form-field-type-file.mat-form-field-should-float .mat-form-field-label-wrapper {
  top: -8px;
}

.angular-editor-wrapper img {
  max-width: 90%;
}
